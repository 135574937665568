import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import TimeRangeSelector from './TimeRangeSelector';
import { subYears } from 'date-fns';
import FilteredPostsDisplay from './FilteredPostsDisplay';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CompetitorChurnPlot = ({ posts, product_name }) => {
    const [competitors, setCompetitors] = React.useState({});
    const [selectedCompetitors, setSelectedCompetitors] = React.useState([]);
    const [postsReferenced, setPostsReferenced] = React.useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = React.useState(null);
    const [timeRange, setTimeRange] = React.useState('3y');

    React.useEffect(() => {
        const competitorCounts = {};
        const now = new Date();
        
        const filteredPosts = (posts || []).filter(post => {
            if (timeRange === 'all') return true;
            const date = new Date(post?.time * 1000);
            const years = parseInt(timeRange);
            return date >= subYears(now, years);
        });

        filteredPosts.forEach(item => {
            if (!item?.post_assessments) return;
            const assessment = item.post_assessments.find(a => a?.type === 'leaver_joiner');
            if (assessment?.details?.competitor) {
                const competitor = assessment.details.competitor;
                if (!competitorCounts[competitor]) {
                    competitorCounts[competitor] = { total: 0, leaving: 0, joining: 0 };
                }
                competitorCounts[competitor].total += 1;
                
                if (assessment.rating < 0) {
                    competitorCounts[competitor].leaving += 1;
                } else if (assessment.rating > 0) {
                    competitorCounts[competitor].joining += 1;
                }
            }
        });

        const sortedCompetitors = Object.entries(competitorCounts)
            .sort((a, b) => b[1].total - a[1].total)
            .slice(0, 5);

        setCompetitors(Object.fromEntries(sortedCompetitors));
        setSelectedCompetitors(Object.keys(Object.fromEntries(sortedCompetitors)));
    }, [posts, timeRange]);

    const prepareChartData = () => {
        const sortedCompetitors = selectedCompetitors
            .sort((a, b) => {
                const totalA = (competitors[a]?.joining || 0) + Math.abs(competitors[a]?.leaving || 0);
                const totalB = (competitors[b]?.joining || 0) + Math.abs(competitors[b]?.leaving || 0);
                return totalB - totalA;
            });

        const labels = sortedCompetitors;
        const joiningData = labels.map(label => competitors[label]?.joining || 0);
        const leavingData = labels.map(label => -(competitors[label]?.leaving || 0));
        const netData = joiningData.map((joining, index) => joining + leavingData[index]);
    
        return {
            labels,
            datasets: [
                {
                    label: 'Joining',
                    data: joiningData,
                    backgroundColor: 'rgba(144, 238, 144, 0.7)',
                    borderColor: 'rgba(144, 238, 144, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Leaving',
                    data: leavingData,
                    backgroundColor: 'rgba(255, 99, 71, 0.7)',
                    borderColor: 'rgba(255, 99, 71, 1)',
                    borderWidth: 1,
                },
                {
                    type: 'line',
                    label: 'Net',
                    data: netData,
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    borderWidth: 2,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    fill: false,
                }
            ]
        };
    };

    const handleChartClick = (event, elements) => {
        if (elements.length > 0) {
            const { index, datasetIndex } = elements[0];
            const competitor = selectedCompetitors[index];
            setSelectedCompetitor(competitor);

            const isJoining = datasetIndex === 0;
            const isLeaving = datasetIndex === 1;
            
            if (isJoining || isLeaving) {
                const competitorPosts = (posts || []).filter(post => 
                    post?.post_assessments?.some(a => {
                        if (a?.type !== 'leaver_joiner' || 
                            a?.details?.competitor !== competitor) {
                            return false;
                        }
                        return isJoining ? a.rating > 0 : a.rating < 0;
                    })
                )
                .map(post => {
                    const assessment = post?.post_assessments?.find(a => 
                        a?.type === 'leaver_joiner' && 
                        a?.details?.competitor === competitor
                    );
                    return {
                        ...post,
                        rating: assessment?.rating,
                        confidence: assessment?.confidence,
                        explanation: assessment?.explanation,
                        ratingName: 'Net Add Rating',
                        ratingType: 'leaver_joiner'
                    };
                });
                setPostsReferenced(competitorPosts);
            }
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                <TimeRangeSelector
                    timeRange={timeRange}
                    onTimeRangeChange={setTimeRange}
                    periods={['1y', '3y', '5y', 'all']}
                />
            </div>
            <Bar
                data={prepareChartData()}
                options={{
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Competitors'
                            }
                        },
                        y: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Number of Mentions'
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const value = Math.abs(context.parsed.y);
                                    const dataset = context.dataset.label;
                                    return `${dataset}: ${value}`;
                                }
                            }
                        }
                    },
                    onClick: handleChartClick,
                }}
            />

            <FilteredPostsDisplay 
                posts={postsReferenced}
                onClose={() => setPostsReferenced([])}
                title="Referenced Posts"
                subtitle={selectedCompetitor ? `${product_name} vs. ${selectedCompetitor}` : null}
                ratingDisplayName="Net Add Rating"
                ratingColorScheme={{high: 0, low: 0}}
                autoScroll = {true}
            />
        </div>
    );
};

export default CompetitorChurnPlot; 