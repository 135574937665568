import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FilteredPostsDisplay from '../components/FilteredPostsDisplay';
import SearchBar from '../components/SearchBar';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress component from Material-UI
import DateRangePicker from '../components/DateRangePicker';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BackToTop from '../components/BackToTop'; // Add this import
import QuestionAnswer from '../components/QuestionAnswer';


function Main() {   
    
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false); // State to track loading status
    const [noResults, setNoResults] = useState(false);
    const [answer, setAnswer] = useState(null); // Add this state
    
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q') || '';
    const startDateParam = searchParams.get('start') || '';
    const endDateParam = searchParams.get('end') || '';

    const [startDate, setStartDate] = useState(startDateParam ? new Date(parseInt(startDateParam)) : null);
    const [endDate, setEndDate] = useState(endDateParam ? new Date(parseInt(endDateParam)) : null);

    const isMobile = window.innerWidth <= 768;
    const searchWidth = isMobile ? '80%' : '70%';
    const headerFontSize = isMobile ? '30px' : '30px';

    useEffect(() => {
        document.title = 'Search';
    }, []);

    const updateUrlWithDates = (start, end) => {
        console.log('updateUrlWithDates called with:', start, end);
        const newSearchParams = new URLSearchParams(searchParams);
        if (start) {
            console.log('Setting start date in URL:', start.valueOf());
            newSearchParams.set('start', start.valueOf());
        } else {
            newSearchParams.delete('start');
        }
        if (end) {
            console.log('Setting end date in URL:', end.valueOf());
            newSearchParams.set('end', end.valueOf());
        } else {
            newSearchParams.delete('end');
        }
        const newUrl = `${location.pathname}?${newSearchParams.toString()}`;
        console.log('New URL:', newUrl);
        navigate(newUrl);
    };

    const handleDateChange = (start, end) => {
        console.log('handleDateChange called with:', start, end);
        setStartDate(start);
        setEndDate(end);
        updateUrlWithDates(start, end);
    };

    useEffect(() => {
        console.log('startDate changed:', startDate);
        console.log('endDate changed:', endDate);
    }, [startDate, endDate]);

    return (
        <div>
            <div className='search-bar-container'>
                <div className='search-bar-header'>
                    <a className='search-title' style={{'fontSize':headerFontSize}}href="/" >Scuttlebutt Research</a>
                </div>
                <div style={{width: searchWidth}}>
                    <SearchBar 
                        setData={setData}
                        startDate={startDate}
                        endDate={endDate}
                        setLoading={setLoading}
                        setNoResults={setNoResults}
                        setAnswer={setAnswer}
                        onSearch={(newQuery) => {
                            setAnswer(null);
                            
                            const newSearchParams = new URLSearchParams(searchParams);
                            newSearchParams.set('q', newQuery);
                            if (startDate) newSearchParams.set('start', startDate.valueOf());
                            if (endDate) newSearchParams.set('end', endDate.valueOf());
                            navigate(`/search?${newSearchParams.toString()}`);
                        }}
                    />
                </div>
            </div>
            <DateRangePicker 
                onDateChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
            />
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <QuestionAnswer 
                        answer={answer} 
                        supportingPosts={data}
                    />
                    {data && (
                        <div className='search-results-container centered'>
                            <div className='search-results standard-width' style={{width: searchWidth}}>
                                <FilteredPostsDisplay 
                                    posts={data}
                                    title={answer ? "Supporting Evidence" : "Relevant Posts"}
                                    query={query}
                                    hideHeader={false}
                                    showSortControls={true}
                                    defaultSortMethod="mostRelevant"
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            
            <BackToTop />
        </div>
    );
}


export default Main; 

