import React, { useState, useEffect } from 'react';
import PostList from './PostList';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BackToTop from './BackToTop';
import '../styles/FilteredPostsDisplay.css';

const POSTS_PER_PAGE = 20;

// Configurable weights for relevance scoring
const RELEVANCE_WEIGHTS = {
    RATING_WEIGHT: 1.0,        // How much to weight the rating's extremity
    CREDIBILITY_WEIGHT: 0.6,   // How much to weight the credibility score
    CONFIDENCE_WEIGHT: 1.0,    // How much to weight the confidence score
    RECENCY_WEIGHT: 0.6        // Small bonus for more recent posts
};

// Constants for relevance scoring
const NEUTRAL_RATING = 5.5;      // The neutral point for 0 to 10 scale
const MAX_RATING = 10;         // Maximum possible rating
const MAX_TIME_DIFF = 365 * 24 * 60 * 60; // One year in seconds

// Calculate a post's relevance score
const calculateRelevanceScore = (post) => {
    if (!post || post.rating === null || post.rating === undefined) return -Infinity;

    // Calculate rating extremity (0 to 1, where 1 is most extreme)
    let ratingExtremity;
    if (post.rating >= 0) {
        ratingExtremity = Math.abs(post.rating - NEUTRAL_RATING) / NEUTRAL_RATING;
    } else {
        ratingExtremity = Math.abs(post.rating) / MAX_RATING;
    }

    // Normalize credibility and confidence to 0-1 range
    const credibility = (post.credibility ?? 0.5) / 10;
    const confidence = (post.confidence ?? 0.5) / 10;

    // Calculate time recency (1.0 for now, decreasing over time)
    const now = Math.floor(Date.now() / 1000);
    const timeDiff = Math.min(now - (post.time ?? now), MAX_TIME_DIFF);
    const recency = 1 - (timeDiff / MAX_TIME_DIFF);

    // Calculate weighted score
    return (
        (ratingExtremity * RELEVANCE_WEIGHTS.RATING_WEIGHT) +
        (credibility * RELEVANCE_WEIGHTS.CREDIBILITY_WEIGHT) +
        (confidence * RELEVANCE_WEIGHTS.CONFIDENCE_WEIGHT) +
        (recency * RELEVANCE_WEIGHTS.RECENCY_WEIGHT)
    ) / (
        RELEVANCE_WEIGHTS.RATING_WEIGHT +
        RELEVANCE_WEIGHTS.CREDIBILITY_WEIGHT +
        RELEVANCE_WEIGHTS.CONFIDENCE_WEIGHT +
        RELEVANCE_WEIGHTS.RECENCY_WEIGHT
    );
};

// Sort functions for different sorting options
const sortFunctions = {
    mostRelevant: (a, b) => calculateRelevanceScore(b) - calculateRelevanceScore(a),
    ratingHighToLow: (a, b) => (b.rating ?? -Infinity) - (a.rating ?? -Infinity),
    ratingLowToHigh: (a, b) => (a.rating ?? Infinity) - (b.rating ?? Infinity),
    newest: (a, b) => (b.time ?? 0) - (a.time ?? 0),
    oldest: (a, b) => (a.time ?? 0) - (b.time ?? 0)
};

// Custom dropdown component
function SortDropdown({ value, onChange, options }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="custom-dropdown">
            <button 
                className={`dropdown-button ${isOpen ? 'open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {options.find(opt => opt.value === value)?.label || 'Sort By'}
                <KeyboardArrowDownIcon />
            </button>
            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option) => (
                        <button
                            key={option.value}
                            className={`dropdown-item ${option.value === value ? 'selected' : ''}`}
                            onClick={() => {
                                onChange(option.value);
                                setIsOpen(false);
                            }}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

const FilteredPostsDisplay = ({ 
    posts, 
    onClose, 
    title = "Referenced Posts",
    subtitle,
    showBookmarkButton = false,
    ratingDisplayName,
    id = 'filtered-posts',
    autoScroll = false,
    ratingColorScheme,
    query = null,
    hideHeader = false,
    disablePagination = false,
    showSortControls = true,
    defaultSortMethod = 'mostRelevant',
    showCloseButton = true
}) => {
    const [displayCount, setDisplayCount] = useState(POSTS_PER_PAGE);
    const [sortMethod, setSortMethod] = useState(defaultSortMethod);

    // Check if posts have ratings
    const hasRatings = posts.some(post => post.rating !== null && post.rating !== undefined);

    useEffect(() => {
        if (autoScroll && posts.length > 0) {
            console.log('autoScroll to posts section');
            const postsSection = document.getElementById(id);
            if (postsSection) {
                const padding = 140;
                const targetPosition = postsSection.getBoundingClientRect().top + window.pageYOffset - padding;
                console.log('targetPosition', targetPosition);
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [posts, id, autoScroll]);

    // Define available sort options based on whether ratings exist
    const sortOptions = [
        ...(hasRatings && ratingDisplayName ? [
            { value: 'mostRelevant', label: 'Most Relevant' },
            { value: 'ratingHighToLow', label: `${ratingDisplayName} (High to Low)` },
            { value: 'ratingLowToHigh', label: `${ratingDisplayName} (Low to High)` }
        ] : []),
        { value: 'newest', label: 'Newest First' },
        { value: 'oldest', label: 'Oldest First' }
    ];

    // Ensure sort method is valid for current options
    useEffect(() => {
        if (!sortOptions.some(opt => opt.value === sortMethod)) {
            setSortMethod(hasRatings ? 'mostRelevant' : 'newest');
        }
    }, [hasRatings, sortOptions, sortMethod]);

    if (posts.length === 0) return null;

    // Sort and paginate posts
    const sortedPosts = [...posts].sort(sortFunctions[sortMethod]);
    const displayedPosts = disablePagination ? sortedPosts : sortedPosts.slice(0, displayCount);
    const hasMorePosts = !disablePagination && sortedPosts.length > displayCount;

    return (
        <div id={id} className="filtered-posts-section">
            {!hideHeader && (
                <>
                    {onClose && showCloseButton && (
                        <div className="filtered-posts-close">
                            <button 
                                onClick={onClose} 
                                className="filtered-posts-close-button"
                                aria-label="Close posts"
                            >
                                <CloseIcon />
                            </button>
                        </div>
                    )}
                    <div className="filtered-posts-header">
                        <div className="filtered-posts-titles">
                            <h3 className="filtered-posts-title">{title}</h3>
                            {subtitle && <span className="filtered-posts-subtitle">•</span>}
                            {subtitle && <p className="filtered-posts-subtitle">{subtitle}</p>}
                        </div>
                        {showSortControls && sortedPosts.length > 1 && (
                            <div className="sort-controls">
                                <SortDropdown
                                    value={sortMethod}
                                    onChange={setSortMethod}
                                    options={sortOptions}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}

            <PostList 
                posts={displayedPosts}
                showBookmarkButton={showBookmarkButton}
                ratingDisplayName={ratingDisplayName}
                ratingColorScheme={ratingColorScheme}
                query={query}
            />

            {hasMorePosts && (
                <div className="view-more-container">
                    <button 
                        className="view-more-button"
                        onClick={() => setDisplayCount(prev => prev + POSTS_PER_PAGE)}
                    >
                        View More
                    </button>
                </div>
            )}
            <BackToTop />
        </div>
    );
};

export default FilteredPostsDisplay; 