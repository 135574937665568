import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import FilteredPostsDisplay from '../components/FilteredPostsDisplay';
import CircularProgress from '@mui/material/CircularProgress';

function PostDetail() {
    const { serverAddress } = useAuth();
    const [threadStructure, setThreadStructure] = useState(null);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchThreadStructure = async () => {
            try {
                const response = await fetch(`${serverAddress}thread/${id}`);
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const data = await response.json();
                    setThreadStructure(data);
                    console.log('Thread structure:', data);
                } else {
                    const text = await response.text();
                    throw new Error(`Received non-JSON response: ${text.substring(0, 100)}...`);
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching thread structure:', err);
            }
        };

        fetchThreadStructure();
    }, [id, serverAddress]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!threadStructure) {
        return (
            <div className='progress-indicator' style={{height: '60px', paddingTop: '120px', paddingBottom: '10px'}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="scene">
            {/* <Helmet>
                <title> Scuttlebutt Post </title>

            </Helmet> */}
            <div className='centered-container'>
                <div className='standard-width'>
                    <br/>
                    <div>
                    <h1>Post</h1>
                    <FilteredPostsDisplay 
                        posts={[threadStructure]} // Wrap in array as we're displaying a single post
                        showBookmarkButton={true}
                        ratingThresholds={{ low: 6, high: 8 }}
                        ratingDisplayName="Rating"
                        hideHeader={true}
                        showSortControls={false}
                        disablePagination={true}
                    />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostDetail;
