import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const TimeRangeSelector = ({ 
    timeRange,
    onTimeRangeChange,
    showLTM = false,
    onLTMChange,
    ltmSelected,
    periods = ['1y', '3y', '5y', '10y', 'all'], // Customizable periods
    defaultPeriod = '3y', // Default selected period
    periodLabels = {  // Custom labels for periods
        '1y': '1Y',
        '3y': '3Y',
        '5y': '5Y',
        '10y': '10Y',
        'all': 'All',
        'ytd': 'YTD',
        'ltm': 'LTM'
    },
    size = "small",
    className = "time-range-toggle"
}) => {
    const handleTimeRangeChange = (event, newRange) => {
        if (newRange !== null) {
            onTimeRangeChange(newRange);
        }
    };

    return (
        <div className="time-range-selector">
            <ToggleButtonGroup
                value={timeRange || defaultPeriod}
                exclusive
                onChange={handleTimeRangeChange}
                aria-label="time range"
                size={size}
                className={className}
            >
                {periods.map(period => (
                    <ToggleButton 
                        key={period} 
                        value={period}
                        aria-label={periodLabels[period] || period}
                    >
                        {periodLabels[period] || period.toUpperCase()}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {showLTM && (
                <ToggleButton
                    value="ltm"
                    selected={ltmSelected}
                    onChange={() => onLTMChange(!ltmSelected)}
                    size={size}
                >
                    {periodLabels.ltm || 'LTM'}
                </ToggleButton>
            )}
        </div>
    );
};

export default TimeRangeSelector;