import React from 'react';
import PostV2 from './PostV2';

const PostList = ({ 
    posts,
    showBookmarkButton = false,
    query = null,
    ratingThresholds,
    ratingDisplayName,
    sources,
    ratingColorScheme
}) => {
    if (!posts || posts.length === 0) {
        return (
            <div className='no-results'>
                <p>No results found.</p>
            </div>
        );
    }

    return (
        <div className="post-list">
            {posts.map(post => (
                <PostV2
                    key={post.id}
                    post={post}
                    showBookmarkButton={showBookmarkButton}
                    query={query}
                    ratingThresholds={ratingThresholds}
                    ratingDisplayName={ratingDisplayName}
                    sources={sources}
                    isTopLevel={true}
                    ratingColorScheme={ratingColorScheme}
                />
            ))}
        </div>
    );
};

export default PostList; 