import React from 'react';
import '../styles/NPSChart.css'; // We'll reuse the toggle styles from NPSChart.css

const Toggle = ({ label, checked, onChange, size = 'medium' }) => {
    const handleChange = (e) => {
        onChange(e.target.checked);
    };

    return (
        <div className={`custom-toggle ${size}`}>
            <label className="toggle-label">
                <input 
                    type="checkbox" 
                    checked={checked} 
                    onChange={handleChange}
                />
                <span className="toggle-slider"></span>
            </label>
            <span className="toggle-text">{label}</span>
        </div>
    );
};

export default Toggle; 