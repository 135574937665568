import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import debounce from 'lodash/debounce';
import supabase from './supabaseClient';
import SearchPreviewResultCompany from './SearchPreviewResultCompany';
import '../styles/SearchPreview.css';

const ANIMATION_DURATION = 200;
const CACHE_SIZE = 50; // Store last 50 searches
const CACHE_EXPIRY = 5 * 60 * 1000; // Cache expires after 5 minutes

// Simple LRU cache implementation
class SearchCache {
  constructor(maxSize = CACHE_SIZE) {
    this.cache = new Map();
    this.maxSize = maxSize;
  }

  get(key) {
    const item = this.cache.get(key);
    if (!item) return null;
    
    // Check if cache entry has expired
    if (Date.now() - item.timestamp > CACHE_EXPIRY) {
      this.cache.delete(key);
      return null;
    }
    
    // Move to front (most recently used)
    this.cache.delete(key);
    this.cache.set(key, item);
    return item.data;
  }

  set(key, value) {
    // Remove oldest entry if cache is full
    if (this.cache.size >= this.maxSize) {
      const firstKey = this.cache.keys().next().value;
      this.cache.delete(firstKey);
    }
    
    this.cache.set(key, {
      data: value,
      timestamp: Date.now()
    });
  }
}

// Create a singleton cache instance
const searchCache = new SearchCache();

const SearchPreviewNew = ({
  query,
  parentRef,
  anchorRef,
  onlyShowCompanySearch = false,
  handleSearch,
  handleClick,
  previewTextOverride,
  setCompany,
  onClear,
  onVisibilityChange
}) => {
  const [results, setResults] = useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0, width: 0 });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const previewRef = useRef(null);
  const navigate = useNavigate();
  const animationTimeoutRef = useRef(null);
  const lastQueryRef = useRef('');

  // Create a debounced search function
  const debouncedSearch = useRef(
    debounce(async (searchTerm) => {
      if (!searchTerm || searchTerm.length < 2) {
        setResults([]);
        setIsLoading(false);
        return;
      }

      // Check cache first
      const cachedResults = searchCache.get(searchTerm.toLowerCase());
      if (cachedResults) {
        console.log('Using cached results:', cachedResults);
        setResults(cachedResults);
        setIsLoading(false);
        return;
      }

      // Only set loading if we don't have cached results
      setIsLoading(true);
      
      try {
        console.log('Searching for:', searchTerm);
        const { data, error } = await supabase
          .rpc('search_companies', { search_term: searchTerm });

        if (error) throw error;
        
        console.log('Search results:', data);
        const searchResults = data || [];
        
        // Cache the results
        searchCache.set(searchTerm.toLowerCase(), searchResults);
        
        // Only update results if this is still the latest query
        if (searchTerm === lastQueryRef.current) {
          setResults(searchResults);
        }
      } catch (error) {
        console.error('Search error:', error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300)
  ).current;

  // Cleanup function for animations and debounce
  const cleanup = useCallback(() => {
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }
    debouncedSearch.cancel();
  }, [debouncedSearch]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  // Update search when query changes
  useEffect(() => {
    lastQueryRef.current = query;
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  // Position the preview dropdown
  const updatePosition = useCallback(() => {
    const referenceElement = anchorRef?.current || parentRef.current;
    if (!referenceElement || !isActive) return;

    const rect = referenceElement.getBoundingClientRect();
    setPosition({
      x: rect.left,
      y: rect.bottom,
      width: rect.width
    });
  }, [parentRef, anchorRef, isActive]);

  useEffect(() => {
    const referenceElement = anchorRef?.current || parentRef.current;
    if (!referenceElement || !isActive) return;

    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(referenceElement);
    updatePosition();

    return () => resizeObserver.disconnect();
  }, [updatePosition, isActive]);

  // Handle visibility changes
  useEffect(() => {
    onVisibilityChange?.(isVisible);
  }, [isVisible, onVisibilityChange]);

  // Keyboard navigation
  const handleKeyDown = useCallback((e) => {
    if (!['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key) || !results.length) return;

    e.preventDefault();
    e.stopPropagation();

    switch (e.key) {
      case 'ArrowDown':
        setSelectedIndex(prev => 
          prev < (onlyShowCompanySearch ? results.length - 1 : results.length) 
            ? prev + 1 
            : 0
        );
        break;
      case 'ArrowUp':
        setSelectedIndex(prev => 
          prev > 0 
            ? prev - 1 
            : (onlyShowCompanySearch ? results.length - 1 : results.length)
        );
        break;
      case 'Enter':
        if (selectedIndex === 0 && !onlyShowCompanySearch) {
          handleSearch?.(query);
        } else {
          const company = results[onlyShowCompanySearch ? selectedIndex : selectedIndex - 1];
          if (company) {
            handleItemClick(company);
          }
        }
        break;
    }
  }, [results, selectedIndex, onlyShowCompanySearch, handleSearch, query]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
    return () => document.removeEventListener('keydown', handleKeyDown, true);
  }, [handleKeyDown]);

  const handleItemClick = useCallback((company) => {
    if (handleClick) {
      handleClick(company);
    } else if (setCompany) {
      setCompany(company);
    } else {
      // Record the visit before navigation
      supabase.rpc('record_company_visit', { 
        p_company_id: company.id,
        p_visit_source: 'search'
      }).then(() => {
        navigate(`/company/${company.id}`, {
          state: { source: 'search' }
        });
      }).catch(error => {
        console.error('Failed to record visit:', error);
        // Still navigate even if recording fails
        navigate(`/company/${company.id}`, {
          state: { source: 'search' }
        });
      });
    }
    
    setIsVisible(false);
    onClear?.();
    
    animationTimeoutRef.current = setTimeout(() => {
      setIsActive(false);
      parentRef.current?.blur();
    }, ANIMATION_DURATION);
  }, [handleClick, setCompany, navigate, onClear, parentRef]);

  // Focus and click outside handlers
  useEffect(() => {
    const handleFocus = () => {
      setIsActive(true);
      animationTimeoutRef.current = setTimeout(() => {
        setIsVisible(true);
      }, 50);
    };

    const handleClickOutside = (event) => {
      const referenceElement = anchorRef?.current || parentRef.current;
      if (previewRef.current && 
          !previewRef.current.contains(event.target) && 
          !referenceElement.contains(event.target)) {
        setIsVisible(false);
        animationTimeoutRef.current = setTimeout(() => {
          setIsActive(false);
        }, ANIMATION_DURATION);
      }
    };

    const input = parentRef.current;
    input?.addEventListener('focus', handleFocus);
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      input?.removeEventListener('focus', handleFocus);
      document.removeEventListener('mousedown', handleClickOutside);
      cleanup();
    };
  }, [parentRef, anchorRef, cleanup]);

  // Scroll handler
  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(false);
      animationTimeoutRef.current = setTimeout(() => {
        setIsActive(false);
      }, ANIMATION_DURATION);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Render preview
  const renderPreview = () => {
    if ((!results.length && !isLoading) || !isActive) return null;

    const previewContent = (
      <div
        ref={previewRef}
        className={`search-preview-container ${isVisible ? 'visible' : ''}`}
        style={{
          position: 'fixed',
          top: position.y,
          left: position.x,
          width: position.width,
        }}
      >
        {isLoading ? (
          <div className="search-preview-item loading">
            Loading...
          </div>
        ) : (
          <>
            {!onlyShowCompanySearch && (
              <div
                className={`search-preview-item ${selectedIndex === 0 ? 'selected' : ''}`}
                onClick={() => handleSearch?.(query)}
              >
                {previewTextOverride || `Search for Scuttlebutt about "${query}"`}
              </div>
            )}
            
            {results.map((company, index) => {
              const itemIndex = onlyShowCompanySearch ? index : index + 1;
              return (
                <SearchPreviewResultCompany 
                  key={company.id}
                  company={company}
                  isSelected={itemIndex === selectedIndex}
                  onClick={handleItemClick}
                />
              );
            })}
          </>
        )}
      </div>
    );

    return createPortal(previewContent, document.body);
  };

  return renderPreview();
};

export default SearchPreviewNew; 