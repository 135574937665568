import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthContext';
import FilteredPostsDisplay from '../components/FilteredPostsDisplay';
import supabase from '../components/supabaseClient';
import BackToTop from '../components/BackToTop';

const ThematicSearch = () => {
    const [themes, setThemes] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState('');
    const [topic, setTopic] = useState('');
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { flaskServerAddress } = useAuth();
    const [weight, setWeight] = useState(0.45);
    const [searchedTheme, setSearchedTheme] = useState('');
    const [searchedTopic, setSearchedTopic] = useState('');

    useEffect(() => {
        fetchThemes();
    }, []);

    const fetchThemes = async () => {
        try {
            const { data, error } = await supabase
                .from('themes')
                .select('id, theme, display_name, description')
                .order('display_name', { ascending: true });

            if (error) {
                throw error;
            }

            setThemes(data);
        } catch (error) {
            console.error('Error fetching themes:', error);
        }
    };

    const handleSearch = async () => {
        if (!selectedTheme) {
            alert('Please select a theme');
            return;
        }

        setIsLoading(true);
        try {
            const url = `${flaskServerAddress}thematic-search/${selectedTheme}/?weight=${topic ? weight : 0.35}${topic ? `&topic=${encodeURIComponent(topic)}` : ''}`;
            const response = await fetch(url);
            const data = await response.json();
            setPosts(data);
            setSearchedTheme(themes.find(t => t.theme === selectedTheme)?.display_name || '');
            setSearchedTopic(topic);
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="thematic-search-container">
            <h1>Thematic Search</h1>
            <div className="thematic-search-guide">
                <p>Explore posts related to specific themes and topics:</p>
                <ol>
                    <li>Select a theme from the dropdown menu</li>
                    <li>Optionally, enter a topic (e.g., company or product name)</li>
                    <li>Click "Search" to find relevant posts</li>
                </ol>
            </div>
            <div className="" style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                    <select
                        value={selectedTheme}
                        onChange={(e) => setSelectedTheme(e.target.value)}
                        className="theme-select"
                    >
                        <option value="">Select a theme</option>
                        {themes.map((theme) => (
                            <option key={theme.id} value={theme.theme}>
                                {theme.display_name}
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter a topic (optional)"
                        className="topic-input"
                    />
                    <button onClick={handleSearch} className="search-button">
                        Search
                    </button>
                </div>

                {topic && (
                    <div className="weight-slider">
                        <label htmlFor="weight-input">Topic Weight: {weight.toFixed(2)}</label>
                        <input
                            id="weight-input"
                            type="range"
                            min="0"
                            max="1"
                            step="0.05"
                            value={weight}
                            onChange={(e) => setWeight(parseFloat(e.target.value))}
                            className="weight-input"
                        />
                    </div>
                )}
            </div>
            {isLoading ? (
                <div className="loading">Loading...</div>
            ) : (
                <FilteredPostsDisplay
                    posts={posts}
                    title={searchedTheme ? `Results for ${searchedTheme}${searchedTopic ? ` and "${searchedTopic}"` : ''}` : ''}
                    showBookmarkButton={true}
                    query={searchedTopic}
                    hideHeader={false}
                    showSortControls={true}
                    defaultSortMethod="mostRelevant"
                />
            )}
            <BackToTop />
        </div>
    );
};

export default ThematicSearch;
