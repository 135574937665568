import React from 'react';
import { Line } from 'react-chartjs-2';
import ProductSelector from './ProductSelector';
import TimeRangeSelector from './TimeRangeSelector';
import Toggle from './Toggle';
import '../styles/NPSChart.css';

const MetricCard = ({ period, metrics, onClick, getNPSColorClass }) => {
    return (
        <div className="metric-card" onClick={onClick}>
            <h3>{period}</h3>
            
            <div className="metric-row">
                <span className="metric-label">NPS (Adjusted)</span>
                <span className={`metric-value ${getNPSColorClass(metrics.nps)}`}>
                    {metrics.nps}
                </span>
            </div>

            <div className="metric-row">
                <span className="metric-label">NPS (Raw)</span>
                <span className={`metric-value ${getNPSColorClass(metrics.noPaddingNps)}`}>
                    {metrics.noPaddingNps}
                </span>
            </div>

            <div className="metric-row">
                <span className="metric-label">Average Rating</span>
                <span className="metric-value rating">
                    {metrics.raw !== null ? `${metrics.raw.toFixed(1)}/10` : '-'}
                </span>
            </div>

            <div className="sample-size">
                Sample size: {metrics.count}
            </div>
        </div>
    );
};

const NPSChart = ({ 
    // Chart data and display
    chartData,
    chartConfig,
    showConfidenceIntervals,
    showCompetitors,
    
    // Product selection
    topics,
    selectedProduct,
    onProductChange,
    
    // Time range
    timeRange,
    onTimeRangeChange,
    
    // Metrics
    averages,
    onMetricCardClick,
    
    // Utility
    getNPSColorClass,
    
    // Competitor toggle
    hasCompetitors,
    onToggleCompetitors,

    // Credible intervals
    setShowConfidenceIntervals
}) => {
    return (
        <div className="nps-chart-container">
            <div className="chart-controls">
                <div className="chart-control-group">
                    <ProductSelector
                        products={topics}
                        selectedProduct={selectedProduct}
                        onProductChange={onProductChange}
                    />
                    {hasCompetitors && (
                        <Toggle
                            label="Show Competitors"
                            checked={showCompetitors}
                            onChange={onToggleCompetitors}
                        />
                    )}
                </div>
                <div className="chart-control-group">
                    <Toggle
                        label="Show Credible Intervals"
                        checked={showConfidenceIntervals}
                        onChange={setShowConfidenceIntervals}
                    />
                    <TimeRangeSelector
                        timeRange={timeRange}
                        onTimeRangeChange={onTimeRangeChange}
                    />
                </div>
            </div>

            <div className="chart-wrapper">
                {chartData && <Line 
                    data={chartData} 
                    options={{
                        ...chartConfig,
                        plugins: {
                            ...chartConfig.plugins,
                            tooltip: {
                                ...chartConfig.plugins.tooltip,
                                callbacks: {
                                    label: function(context) {
                                        const datasetLabel = context.dataset.label || '';
                                        if (datasetLabel.includes('Confidence Band')) {
                                            return null;
                                        }
                                        const value = context.parsed.y;
                                        const dataPoint = context.dataset.data[context.dataIndex];
                                        const confidenceBounds = dataPoint.confidenceBounds;
                                        if (confidenceBounds) {
                                            const labels = [
                                                `${datasetLabel}: ${value.toFixed(1)}`,
                                                `95% Credible Interval: [${confidenceBounds.lower.toFixed(1)}, ${confidenceBounds.upper.toFixed(1)}]`
                                            ];
                                            
                                            // Only show sample size if it's greater than 0 (not forward-filled)
                                            if (dataPoint.count > 0) {
                                                labels.push(`Sample Size: ${dataPoint.count}`);
                                            }
                                            
                                            return labels;
                                        }
                                        return `${datasetLabel}: ${value.toFixed(1)}`;
                                    }
                                }
                            }
                        }
                    }}
                    plugins={[{
                        id: 'backgroundColorPlugin',
                        beforeDraw: (chart) => {
                            const ctx = chart.canvas.getContext('2d');
                            const chartArea = chart.chartArea;
                            const yAxis = chart.scales.y;
                            const min = -100;
                            const low = 0;
                            const mid = 30;
                            const high = 100;

                            // Red zone (negative NPS)
                            ctx.fillStyle = 'rgba(255, 200, 200, 0.5)';
                            ctx.fillRect(
                                chartArea.left, 
                                yAxis.getPixelForValue(min), 
                                chartArea.right - chartArea.left, 
                                yAxis.getPixelForValue(low) - yAxis.getPixelForValue(min)
                            );

                            // Yellow zone (0-30 NPS)
                            ctx.fillStyle = 'rgba(255, 255, 200, 0.5)';
                            ctx.fillRect(
                                chartArea.left, 
                                yAxis.getPixelForValue(mid), 
                                chartArea.right - chartArea.left, 
                                yAxis.getPixelForValue(low) - yAxis.getPixelForValue(mid)
                            );

                            // Green zone (30+ NPS)
                            ctx.fillStyle = 'rgba(200, 255, 200, 0.5)';
                            ctx.fillRect(
                                chartArea.left, 
                                yAxis.getPixelForValue(high), 
                                chartArea.right - chartArea.left, 
                                yAxis.getPixelForValue(mid) - yAxis.getPixelForValue(high)
                            );
                        }
                    }]}
                />}
            </div>

            <div className="chart-metrics">
                <div className="metric-cards">
                    <MetricCard 
                        period="Past Year"
                        metrics={averages.year}
                        onClick={() => onMetricCardClick('year')}
                        getNPSColorClass={getNPSColorClass}
                    />
                    <MetricCard 
                        period="Past 3 Years"
                        metrics={averages.threeYears}
                        onClick={() => onMetricCardClick('threeYears')}
                        getNPSColorClass={getNPSColorClass}
                    />
                    <MetricCard 
                        period="Past 5 Years"
                        metrics={averages.fiveYears}
                        onClick={() => onMetricCardClick('fiveYears')}
                        getNPSColorClass={getNPSColorClass}
                    />
                </div>
            </div>
        </div>
    );
};

export default NPSChart;
