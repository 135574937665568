// This component takes a folder ID, gets the posts in that folder by importing the bookmarks context, and returns a FilteredPostsDisplay component with those posts

import React from 'react';
import { useApiDataContext } from '../ApiDataContext';
import FilteredPostsDisplay from '../FilteredPostsDisplay';
import CircularProgress from '@mui/material/CircularProgress';

const FolderPostDisplay = ({header, posts, showBookmarkButton, sources}) => {
    return (
        <div>
            {posts && posts.length > 0 && (
                <div>
                    <FilteredPostsDisplay 
                        posts={posts}
                        showBookmarkButton={showBookmarkButton}
                        sources={sources}
                        hideHeader={true}
                        showSortControls={true}
                        defaultSortMethod="newest"
                    />
                </div>
            )}
            {posts && posts.length === 0 && (
                <div className='no-bookmarks'>
                </div>
            )}
            {!posts && (
                <div className='progress-indicator'>
                    <CircularProgress />
                </div>
            )}
        </div>
    )
}

export default FolderPostDisplay;