import { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import SearchPreviewNew from './SearchPreviewNew';

const ANIMATION_DURATION = 200; // Consistent animation timing

function HeaderSearchBar() {
    const navigate = useNavigate();
    const searchInputRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    
    const handleClearSearch = useCallback(() => {
        setSearchQuery('');
    }, []);

    const handleSearch = useCallback((inputQuery) => {
        const queryToSearch = inputQuery || searchQuery;
        if (!queryToSearch) return;
        
        navigate(`/search?q=${encodeURIComponent(queryToSearch)}`);
        setSearchQuery('');
        setIsExpanded(false);
    }, [navigate, searchQuery]);

    const handleCompanyClick = useCallback((company) => {
        navigate(`/company/${company.id}`);
        setIsExpanded(false);
    }, [navigate]);

    const handleInputFocus = useCallback(() => {
        setIsExpanded(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        // Don't immediately collapse the search bar
        // SearchPreviewNew will handle the actual collapse timing
    }, []);

    return (
        <div className={`header-search-container ${isExpanded ? 'focused' : ''}`}>
            <div className={`search-icon-wrapper ${isExpanded ? 'focused' : ''}`}>
                <SearchIcon />
            </div>
            <input
                ref={searchInputRef}
                type="text"
                className="header-search-input"
                placeholder="Search companies..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
            />
            <SearchPreviewNew
                query={searchQuery}
                parentRef={searchInputRef}
                onlyShowCompanySearch={false}
                handleClick={handleCompanyClick}
                handleSearch={handleSearch}
                onClear={handleClearSearch}
                onVisibilityChange={setIsExpanded}
            />
        </div>
    );
}

export default HeaderSearchBar; 