import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FilteredPostsDisplay from '../components/FilteredPostsDisplay';
import { useApiDataContext } from '../components/ApiDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';

function FolderSimilarPosts () {
    const { folders } = useApiDataContext();
    const { folder_id } = useParams();
    const [similarPosts, setSimilarPosts] = useState([]);
    const [folderName, setFolderName] = useState('');
    const { flaskServerAddress } = useAuth();

    useEffect(() => {
        console.log('selected folder: ', folder_id);
        console.log(folders);
        const folder = folders.find(folder => folder.folder_id === folder_id);
        if (folder) {
            setFolderName(folder.name);
        }
        console.log('folder: ', folder);
        if (folder && similarPosts.length === 0) {
            getSimilarPoststoFolder(folder);
        }
    }, [folder_id, folders]);

    useEffect(() => {
        console.log('similar posts: ', similarPosts);
    }, [similarPosts]);

    const getSimilarPoststoFolder = async (folder) => {
        const body = JSON.stringify(folder);
        fetch(flaskServerAddress + 'similar-posts-for-folder/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
        .then(response => response.json())
        .then(data => {
            const jsonData = JSON.parse(data);
            setSimilarPosts(jsonData);
        })
        .catch((error) => {
            console.error('Error:', error);
            setSimilarPosts([]);
        });
    }

    return (
        <div className="scene">
            <div className='post-details-container similar-posts centered-container'>
                <div className='post-details similar-posts standard-width'>
                    {similarPosts && similarPosts.length > 0 && folderName && (
                        <div className='similar-posts-container'>
                            <h1 className='similar-posts-header'>Similar Posts for {folderName}</h1>
                            <FilteredPostsDisplay 
                                posts={similarPosts}
                                query={folderName}
                                hideHeader={true}
                                showSortControls={true}
                                defaultSortMethod="mostRelevant"
                            />
                        </div>
                    )}
                    {(!similarPosts || similarPosts.length === 0) && (
                        <div className='progress-indicator'>
                            <CircularProgress /> 
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FolderSimilarPosts;