import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

function Modal({ isOpen, onClose, title, children, width="350px" }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="modal-container" style={{ width: width }}>
        <button 
          onClick={onClose} 
          className="modal-close-button"
          aria-label="Close modal"
        >
          <CloseIcon />
        </button>
        {title && <h4>{title}</h4>}
        {children}
      </div>
    </div>
  );
}

export default Modal; 