import { useApiDataContext } from '../components/ApiDataContext';
import { useEffect, useState, useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FolderFolders from '../components/FolderFolders';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { ModalContext } from "../App";
import FilteredPostsDisplay from '../components/FilteredPostsDisplay';

function MyBookmarks() {
    const { folders, bookmarksLoading, uncategorizedBookmarks } = useApiDataContext();
    const [foldersInOrder, setFoldersInOrder] = useState([]);
    const {openNewFolderModal} = useContext(ModalContext);

    useEffect(() => {
        console.log('folders changed!' , folders);
        // sort folders alphabetically
        if (folders) {
            const foldersInOrder = folders.sort((a, b) => a.name.localeCompare(b.name));
            setFoldersInOrder(foldersInOrder);
        }
    }, [folders]);

    const handleNewFolderModalOpen = () => {
        console.log('opening new folder modal');
        openNewFolderModal(null);
    }

    useEffect(() => {
        document.title = 'Bookmarks';
    }, []);
    
    return (
        <div>
            <div className='my-bookmarks-container scene centered'>
                <div className='my-bookmarks standard-width'>
                    <div className='bookmark-folder-toolbar'>
                        <h4 className='my-bookmarks-header'>Bookmarks</h4>
                        <button className='centered-icon-button gray-button' onClick={handleNewFolderModalOpen}>
                            <CreateNewFolderIcon className='icon'/>
                            New Folder
                        </button>
                    </div>
                    <div className='small-divider'/>
                    {folders && 
                        <FolderFolders folders={foldersInOrder.filter(folder => folder.parent === null)} />
                    }
                    {!folders && <CircularProgress />}

                    {bookmarksLoading && (
                        <div className='progress-indicator'>
                            <CircularProgress />
                        </div>
                    )}
                    {uncategorizedBookmarks.length > 0 && (
                        <div className='centered'>
                            <div className='standard-width'>
                                <br/>
                                <FilteredPostsDisplay 
                                    posts={uncategorizedBookmarks}
                                    title="Uncategorized Bookmarks"
                                    hideHeader={false}
                                    showSortControls={true}
                                    defaultSortMethod="newest"
                                />
                            </div>
                        </div>
                    )}
                    {folders.length === 0 && !bookmarksLoading && (
                        <div className='centered'>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <p>You don't have any bookmarks yet! To get started, try searching for something.</p>
                        </div>
                    )}
                </div> 
            </div>
        </div>
    );
}

export default MyBookmarks;